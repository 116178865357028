import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import request from './modules/request'
import settings from './modules/settings'
import service from './services/service'

Vue.use(Vuex)

export const store = new Vuex.Store({
  modules: {
    auth,
    request,
    settings,
    service,
  },
})
export default store
