<template>
  <nav
    class="navbar bg-white custom-nav"
    :class="[{ 'navbar-expand-md': expand }, { 'navbar-transparent': transparent }, { [`bg-${type}`]: type }]"
    style="border-bottom: 1px solid rgba(0, 0, 0, 0.1); box-shadow: rgb(226 230 245) 0px 5px 5px; padding-bottom: 0 !important; height: 60px; position: sticky; top: 0; z-index: 999">
    <!-- Sidebar Toggle Button -->
    <b-button @click="toggleSidebar" variant="outline-dark" class="border-0 bg-transparent px-0">
      <b-icon :icon="sidebarOpen ? 'text-indent-right' : 'text-indent-left'" class="text-xl" />
    </b-button>

    <!-- Breadcrumb -->
    <span v-for="(item, index) in routeList" class="mb-0 mr-1 text-capitalize d-none d-xl-inline-block text-sm font-weight-bold" :key="item">
      {{ index === 0 ? item : '/  ' + item }}
    </span>

    <div class="navbar-nav nav-item collapse navbar-collapse d-flex align-items-center justify-content-end ml-auto">
      <!-- Timezone Select -->
      <base-dropdown class="" tag="li" title-tag="a" menu-classes="dropdown-menu dropdown-menu-right py-0 overflow-hidden">
        <a href="#" slot="title" class="nav-link pr-0" @click.prevent>
          <div class="media align-items-center">
            <span class="mb-0 text-capitalize d-none d-lg-inline-block text-xs font-weight-normal w-fit action" style="min-width: 12rem; white-space: nowrap" :title="todayDateTitle">
              {{ localTz + ' GMT' + todayDate }}
            </span>
          </div>
        </a>
        <b-dropdown-item v-for="(tz, index) in tzOpts" :key="index" @click="changeTimezone(tz)"><b-icon-clock class="mr-2" />{{ tz }}</b-dropdown-item>
      </base-dropdown>

      <!-- Language Select -->
      <base-dropdown class="nav-item" tag="li" title-tag="a" menu-classes="dropdown-menu dropdown-menu-right py-0 overflow-hidden">
        <a href="#" slot="title" class="nav-link pr-0 globe-icon d-inline-flex align-items-center" @click.prevent>
          <div class="media align-items-center d-inline-flex m-0 p-0">
            <span
              class="avatar avatar-sm rounded avatar-image bg-transparent"
              :style="`background-image: url(${$i18n.locale === 'es' ? '/img/icons/flag-mexico-96.png' : '/img/icons/flag-great-britain-96.png'}); width: 26px; height: 26px`"></span>
          </div>
          <span class="ml-1 text-uppercase text-xs" style="background-image: unset !important; width: initial; height: initial; background-color: initial">
            {{ $i18n.locale }}
          </span>
        </a>
        <template v-for="(opt, index) in langOpts">
          <b-dropdown-item
            :key="'lang-opt-' + index"
            v-if="opt.value == 'en' || $store.getters['store'] != 1"
            variant="outline-primary"
            @click.prevent="onChangeLang(opt.value)"
            :disabled="$i18n.locale == opt.value"
            :link-class="`d-flex align-items-center ${$i18n.locale == opt.value ? 'text-white font-weight-bold' : ''}`"
            :style="`opacity: 1; ${$i18n.locale == opt.value ? 'background-color: #5e72e4a6' : ''}`">
            <span class="avatar avatar-sm rounded avatar-image bg-transparent mr-2" :style="`background-image: url(${opt.img}); width: 26px; height: 26px; opacity: 1`"></span>
            {{ opt.text }}
          </b-dropdown-item>
        </template>
      </base-dropdown>
      <confirm-modal id="confirm-locale" :message="$t('notify.translate_notice[1]')" :onSubmit="() => setLang(targetLang)" />
    </div>

    <slot :close-menu="closeMenu"></slot>
  </nav>
</template>
<script>
import langMixin from '@/mixins/lang-mixin'
import { TIMEZONE_LIST } from '@/utils/consts'
import { formatLocalDateTime } from '@/utils/dateTimeUtils'
import { mapGetters } from 'vuex'
export default {
  name: 'base-nav',
  mixins: [
    langMixin, // langOpts, setLang
  ],
  props: {
    type: { type: String, default: '' },
    title: { type: String, default: '' },
    contentId: { type: [String, Number], default: Math.random().toString() },
    transparent: { type: Boolean, default: false },
    expand: { type: Boolean, default: false },
    showToggleButton: { type: Boolean, default: true },
  },
  data() {
    return {
      toggled: false,
      routeList: [],
      todayDate: formatLocalDateTime(null, 'Z | YYYY-MM-DD HH:mm:ss', true),
      todayDateTitle: formatLocalDateTime(null, 'MMMM Do YYYY, HH:mm:ss', true),
      targetLang: this.$i18n.locale,
      needNotify: ['/competitions/product-management/edit', '/competitions/product-management/create', '/competitions/competition-list/edit', '/competitions/competition-list/create'],
    }
  },
  computed: {
    ...mapGetters({
      sidebarOpen: 'settings/sidebarOpen',
      localTz: 'settings/timezone',
    }),
    tzOpts() {
      return TIMEZONE_LIST ?? []
    },
  },
  methods: {
    closeMenu() {
      this.toggled = false
    },
    toggleSidebar() {
      this.$store.dispatch('settings/toggleSidebar', !this.sidebarOpen)
    },
    changeTimezone(val) {
      this.$store.dispatch('settings/changeTimezone', val)
    },
    onChangeLang(val) {
      this.targetLang = val
      if (this.needNotify.indexOf(this.$route.path) > -1) {
        this.$bvModal.show('confirm-locale')
        return
      } else {
        this.setLang(val)
      }
    },
  },
  watch: {
    $route: {
      handler(route) {
        this.routeList = route.fullPath
          .split('/')
          .filter((item) => {
            return item !== ''
          })
          .map((item) => {
            if (item.includes('?')) {
              item = item.split('?')[0]
            }
            return this.$t(`sidebar.${item.replace(/-/g, '_')}`)
          })
      },
      immediate: true,
    },
  },
  mounted() {
    const timer = setInterval(() => {
      this.todayDate = formatLocalDateTime(null, 'Z | YYYY-MM-DD HH:mm:ss', true)
      this.todayDateTitle = formatLocalDateTime(null, 'MMMM Do YYYY, HH:mm:ss', true)
    }, 1000)
    this.$once('hooK:beforeDestroy', () => {
      clearInterval(timer)
    })
  },
}
</script>
