<template>
  <div class="d-flex flex-row justify-content-between justify-content-lg-center">
    <template v-if="hasSlot('sort')"><slot name="sort"></slot></template>
    <b-button variant="basic" class="m-0" @click="onSearch" :disabled="disabled"><b-icon-search class="mr-1" />{{ $t('action.search') }}</b-button>
    <b-button variant="reset" class="my-0 ml-2 mr-0" @click="onReset" :disabled="disabled"><b-icon-x class="mr-1" />{{ $t('action.reset') }}</b-button>
    <template v-if="hasSlot('export')"><slot name="export"></slot></template>
    <b-button v-else-if="showExport && onExport" variant="reset" class="ml-2" @click="excel()"><b-icon-file-spreadsheet class="mr-1" />{{ $t('action.export') }}</b-button>
  </div>
</template>
<script>
import utilsMixin from '@/mixins/utils-mixin'
export default {
  name: 'button-group',
  mixins: [utilsMixin],
  props: { onSearch: Function, onReset: Function, showExport: Boolean, onExport: Function, disabled: Boolean },
  data() {
    return {
      excelLoading: false,
    }
  },
  methods: {
    async excel() {
      if (this.onExport) {
        this.excelLoading = true
        await this.onExport()
        this.excelLoading = false
      }
    },
  },
}
</script>
