import Vue from 'vue'
import vueCookies from '../../plugins/cookies'
import store from '../../store/index'

export default function auth({ next, router }) {
  let isAuthenticated = vueCookies.get('Token')
  if (!isAuthenticated || isAuthenticated == 'null') {
    Vue.notify({ group: 'notification-group', type: 'error', text: 'Your session has been expired, please log in', })
    store.dispatch('logout')
    next({ path: '/login' })
  } else {
    store.dispatch('updateCookie', { key: 'UserID' })
    store.dispatch('updateCookie', { key: 'Email' })
    store.dispatch('updateCookie', { key: 'RoleName' })
    store.dispatch('updateCookie', { key: 'RolePriority' })
    store.dispatch('updateCookie', { key: 'Token' })
    store.dispatch('updateCookie', { key: 'Store' })
    next()
  }
}
