import { TIMEZONE_LIST } from '../../utils/consts'
import { getLocalTimezone, setLocalTimezone } from '../../utils/dateTimeUtils'
import moment from '../../plugins/moment-timezone'

const tzOpts = TIMEZONE_LIST.map(tz => ({ value: tz, text: tz }))

const state = {
    sidebarOpen: true,
    timezone: getLocalTimezone(),
    tzOpts: tzOpts
}

const getters = {}
for (const key in state) {
    getters[key] = (state) => state[key]
}

const mutations = {
    mutate(state, payload) {
        state[payload.property] = payload.with
    }
}

const actions = {
    toggleSidebar({ commit, dispatch }, params) { commit('mutate', { property: 'sidebarOpen', with: params }) },
    changeTimezone({ commit, dispatch }, params) {
        const val = setLocalTimezone(params)
        commit('mutate', { property: 'timezone', with: val })
        moment.tz.setDefault(val)
    },
}

const settings = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}

export default settings
