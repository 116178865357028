import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      storeOpts: [
        { key: 1, value: 1, text: this.$t('uk') },
        { key: 2, value: 2, text: this.$t('address.mexico') },
      ], // constant array
      currencyOpts: [
        { value: 'GBP', text: this.$t('gbp'), sign: '£' },
        { value: 'MXN', text: this.$t('mxn'), sign: 'Mex$' },
      ],
    }
  },

  computed: {
    ...mapGetters({ selfStore: 'store', allowMultiStores: 'allowMultiStores' }),
    selfCurrency() {
      return this.getCurrencyByStoreId(this.selfStore)
    },
  },

  methods: {
    changeStorePlaceholder(trigger, nullable) {
      const index = this.storeOpts.findIndex((x) => x.key == 0)
      if (!trigger && index > -1) {
        this.storeOpts.splice(index, 1)
      } else if (trigger && index == -1) {
        this.storeOpts.splice(0, 0, { key: 0, value: nullable ? null : '', text: this.$t('general.all') + ' ' + this.$tc('general.store', 2) })
      }
    },
    getCurrencyByStoreId(store_id) {
      return this.currencyOpts[store_id - 1].value
    },
    getCurrencySignByStoreId(store_id) {
      return this.currencyOpts[store_id - 1].sign
    },
  },
}
