<template>
  <multiselect
    v-model="array"
    :options="storeOpts"
    label="text"
    track-by="text"
    :preselect-first="true"
    :multiple="true"
    :searchable="false"
    :close-on-select="false"
    :clear-on-select="false"
    :allow-empty="!required"
    :disabled="disabled"
    :placeholder="$tc('general.store', 0)"
    :class="`w-auto multiselect-store ${isSearchRoute ? 'sm-width' : ''}`"
    @select="onSelect"></multiselect>
</template>
<script>
import storeMixin from '@/mixins/store-mixin'
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'

export default {
  name: 'store-multiselect',
  components: { Multiselect },
  mixins: [storeMixin],
  props: {
    store_arr: Array,
    store_ids: String,
    required: Boolean,
    disabled: Boolean,
    defStore: Number,
  },
  data() {
    return {
      isSearchRoute: true,
      notForSearch: ['/competition-list/create', '/competition-list/edit'],
      updateArr: false,
      updateStr: false,
      array: [],
    }
  },
  watch: {
    array(newVal) {
      if (this.defStore && newVal[0]?.value !== this.defStore) {
        this.array.splice(
          0,
          0,
          this.storeOpts.find((x) => x.value == this.defStore)
        )
      } else {
        const noChange = newVal.length === this.store_arr?.length && newVal.every((el, i) => el.value === this.store_arr[i].value)
        if (!noChange && this.updateArr) {
          this.$emit('update:store_arr', newVal)
        }
        if (!noChange && this.updateStr) {
          const tmp = newVal.map((x) => x.value)
          if (tmp.length > 0 && tmp[0]) {
            this.$emit('update:store_ids', `[${tmp}]`)
          } else {
            this.$emit('update:store_ids', null)
          }
        }
      }
    },
    store_ids(newVal, oldVal) {
      if (newVal == null) {
        this.array = [this.storeOpts[0]]
      }
    },
  },
  methods: {
    onSelect(selectedOpt) {
      if (selectedOpt.key == 0) {
        // select [all stores]
        for (let i = 1; i < this.storeOpts.length; i++) {
          const index = this.array.findIndex((x) => x.key == this.storeOpts[i].key)
          if (index > -1) {
            this.array.splice(index, 1)
          }
        }
      } else if (this.array.findIndex((x) => x.key == 0) > -1) {
        this.array.splice(
          this.array.findIndex((x) => x.key == 0),
          1
        )
      }
    },
  },
  created() {
    this.updateArr = this.store_arr !== undefined
    this.updateStr = this.store_ids !== undefined
    this.notForSearch.forEach((route) => {
      this.isSearchRoute = this.isSearchRoute && this.$route.path.indexOf(route) == -1
    })
    this.changeStorePlaceholder(this.isSearchRoute)
    this.array = this.store_arr ?? [this.storeOpts[0]]
  },
}
</script>
<style lang="scss">
.multiselect-store {
  min-height: 36px;
  height: 36px;
  max-height: 36px;
  z-index: 100;
  .multiselect__select {
    min-height: 34px;
    height: 34px;
    max-height: 34px;
  }
  .multiselect__tags {
    min-height: 36px;
    height: 36px;
    max-height: 36px;
    padding-top: 3px;
    border-color: #cad1d7;
  }
  .multiselect__tags-wrap {
    width: max-content;
    display: flex;
    flex-wrap: nowrap;
  }
  .multiselect__tag {
    height: 28px;
    margin-bottom: 0;
    color: #096dd9;
    background-color: #e6f7ff;
    border-color: #91d5ff;
    span {
      font-size: 16px;
      vertical-align: middle;
    }
  }
  .multiselect__tag-icon:hover,
  .multiselect__tag-icon:focus {
    color: #fff;
    background-color: #1890ff;
  }
  .multiselect__option--highlight {
    background-color: #1890ff;
  }
  .multiselect__option--highlight::after {
    display: none;
  }
}
.multiselect--disabled {
  .multiselect__tags {
    background-color: #ededed;
  }
}
.sm-width {
  .multiselect__tags {
    min-width: 6rem;
    width: 100%;
    max-width: 14rem;
  }
}
</style>
