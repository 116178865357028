<template>
  <b-modal
    lazy
    centered
    :id="id ? id : 'confirm'"
    :title="title ? title : 'Confirm Action'"
    title-class="w-100 d-flex justify-content-center align-self-center"
    header-class="py-2"
    body-class="py-0"
    footer-class="py-2"
    :dialog-class="dialogClass"
    @shown="onShown"
    @hidden="onHidden"
    :busy="isLoading"
    cancel-variant="outline-secondary"
    cancel-title="No"
    ok-variant="outline-primary"
    :ok-title="isLoading ? $t('status.loading') : $t('yes')"
    @ok="onSubmit">
    <loading :active="isLoading" :can-cancel="false" :is-full-page="false" />
    <p v-if="message" class="m-0">⚠ {{ message }}</p>
    <template v-if="hasSlot('form')"><slot name="form"></slot></template>
    <template v-if="hasSlot('footer')" #modal-footer="{ cancel }"><slot name="footer" :cancel="cancel"></slot></template>
  </b-modal>
</template>

<script>
import utilsMixin from '@/mixins/utils-mixin';

export default {
  name: 'confirm-modal',
  mixins: [utilsMixin],
  props: {
    id: String,
    title: String,
    dialogClass: String,
    message: String,
    isLoading: Boolean,
    onShown: { type: Function, default: () => {} },
    onHidden: { type: Function, default: () => {} },
    onSubmit: { type: Function, default: () => {} },
  },
}
</script>
