import Vue from "vue"
import { VueAuthenticate } from "vue-authenticate"
import $auth from "vue-authenticate"

import Axios from 'axios'
import VueAxios from 'vue-axios'

import { API_LIST } from '../utils/consts'

const axios = Axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
        //'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
    },
    withCredentials: true,
})

Vue.use(VueAxios, axios)

var auth_options = {
    baseUrl: process.env.VUE_APP_API_BASE_URL,
    tokenName: 'bearer',
    loginUrl: API_LIST.login,
    // logoutUrl: API_LIST.logout,
    registerUrl: API_LIST.register,
    providers: {},

    bindResponseInterceptor: function ($auth) {
        $auth.$http.interceptors.response.use(response => {
            $auth.setToken(response.data)
            return response
        })
    },

    bindRequestInterceptor: function ($auth) {
        const tokenHeader = $auth.options.tokenHeader

        $auth.$http.interceptors.request.use(config => {
            if (Vue.prototype.$referral) {
                config.data.referral = Vue.prototype.$referral
            }
            if ($auth.isAuthenticated()) {
                config.headers[tokenHeader] = [
                    $auth.options.tokenType,
                    $auth.getToken()
                ].join(" ")
            }
            return config
        })
    }
}

const vueAuth = new VueAuthenticate(Vue.prototype.$http, auth_options)

Vue.use($auth, auth_options)

window.auth = vueAuth

export default vueAuth

export { axios as authAxios }