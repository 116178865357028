<template>
  <div class="main-content bg-default">
    <!-- Header -->
    <div class="header bg-gradient-success py-7 py-lg-8 pt-lg-9">
      <div class="container">
        <div class="header-body text-center mb-4">
          <div class="text-center" style="margin-bottom: 5px">
            <b-img-lazy src="/img/brand/logo.png" />
          </div>
        </div>
      </div>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <div class="container mt--8 pb-5 login-container" :key="$i18n.locale">
      <slide-y-up-transition mode="out-in" origin="center top">
        <router-view></router-view>
      </slide-y-up-transition>
    </div>
  </div>
</template>
<script>
import { SlideYUpTransition } from 'vue2-transitions'

export default {
  name: 'auth-layout',
  components: {
    SlideYUpTransition,
  },
}
</script>
<style scoped>
@media screen and (max-width: 768px) {
  .py-7 {
    padding-top: 15rem !important;
  }
  .mt--8 {
    margin-top: -4rem !important;
  }
  .card-body {
    padding-top: 1.5rem;
  }
}
</style>
