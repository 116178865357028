import DateRangePicker from 'vue2-daterange-picker'
import BalanceSpan from '../components/BalanceSpan.vue'
import BaseDropdown from '../components/BaseDropdown.vue'
import BaseNav from '../components/BaseNav.vue'
import BoolBadge from '../components/BoolBadge.vue'
import ButtonGroup from '../components/ButtonGroup.vue'
import Card from '../components/Card.vue'
import ConfirmModal from '../components/ConfirmModal.vue'
import FileUpload from '../components/FileUpload.vue'
import HttpStatusBadge from '../components/HttpStatusBadge.vue'
import IconTranslate from '../components/IconTranslate.vue'
import Paginate from '../components/Paginate.vue'
import StoreBadge from '../components/StoreBadge.vue'
import StoreMultiSelect from '../components/StoreMultiSelect.vue'
import TooltipSpan from '../components/TooltipSpan.vue'

export default {
  install(Vue) {
    Vue.component('date-range-picker', DateRangePicker)
    Vue.component(BalanceSpan.name, BalanceSpan)
    Vue.component(BaseDropdown.name, BaseDropdown)
    Vue.component(BaseNav.name, BaseNav)
    Vue.component(BoolBadge.name, BoolBadge)
    Vue.component(ButtonGroup.name, ButtonGroup)
    Vue.component(Card.name, Card)
    Vue.component(ConfirmModal.name, ConfirmModal)
    Vue.component(FileUpload.name, FileUpload)
    Vue.component(HttpStatusBadge.name, HttpStatusBadge)
    Vue.component(IconTranslate.name, IconTranslate)
    Vue.component(Paginate.name, Paginate)
    Vue.component(StoreBadge.name, StoreBadge)
    Vue.component(StoreMultiSelect.name, StoreMultiSelect)
    Vue.component(TooltipSpan.name, TooltipSpan)
  },
}
