<template>
  <nav
    ref="sidebar"
    class="navbar navbar-vertical fixed-left navbar-expand-md navbar-dark p-0"
    id="sidenav-main"
    style="box-shadow: 5px 0px 5px #e2e6f5; border-bottom: 1px solid white; background-color: rgb(14 27 47); overflow-x: hidden;"
  >
    <div class="container-fluid h-100" :style="sidebarStyle">
      <router-link class="navbar-brand" to="/" style="padding-top: 10px; padding-bottom: 10px; max-height: 5rem">
        <img :src="logo" v-if="sidebarOpen" class="navbar-brand-img" alt="..." style="width: 50%; max-height: 2.5rem" />
        <img :src="icon" v-else class="navbar-brand-img" alt="..." style="width: 40%; max-height: 2.5rem" />
      </router-link>
      <slot></slot>
      <div v-show="$sidebar.showSidebar" class="navbar-collapse collapse show" id="sidenav-collapse-main">
        <ul class="navbar-nav p-0 h-100">
          <slot name="links"></slot>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'sidebar',
  data() {
    return {
      avatar: null,
      showMenu: true,
    }
  },
  props: {
    logo: {
      type: String,
      default: '/img/brand/logo.png',
      description: 'Sidebar app logo',
    },
    icon: {
      type: String,
      default: '/img/brand/icon.png',
      description: 'Sidebar app icon',
    },
    autoClose: {
      type: Boolean,
      default: true,
      description: 'Whether sidebar should autoclose on mobile when clicking an item',
    },
  },
  provide() {
    return {
      autoClose: this.autoClose,
    }
  },
  computed: {
    ...mapGetters({ sidebarOpen: 'settings/sidebarOpen' }),
    sidebarStyle() {
      return this.sidebarOpen ? '' : 'width: 60px'
    },
  },
  methods: {
    closeSidebar() {
      this.$sidebar.displaySidebar(false)
    },
    showSidebar() {
      this.$sidebar.displaySidebar(true)
    },
    async logout() {
      try {
        this.$store.dispatch('logout')
      } catch (error) {
        console.log(this.$t('notify.unknown_err'))
      }
    },
  },
  beforeDestroy() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false
    }
  },
}
</script>
