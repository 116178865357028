<template>
  <div :class="`w-100 d-flex flex-row flex-wrap align-items-start ${showBack ? 'justify-content-between' : 'justify-content-' + align}`">
    <b-button v-if="showBack" @click="$router.back()" class="mt-2 mr-auto" style="width: fit-content"> <b-icon-chevron-left />{{ $t('action.back_to_prev') }} </b-button>
    <ul class="pagination d-flex flex-row align-items-center mb-0 mt-2 ml-2">
      <li class="page-item">
        <span aria-hidden="true">{{ $t('general.total') }}</span>
      </li>
      <li class="page-item mr-1">
        <span aria-hidden="true">{{ total }}</span>
      </li>
      <li class="page-item align-middle mr-1" style="width: 110px !important">
        <b-form-select v-model="perPage" :options="perPageOpts" style="height: 32px !important; border: 1px solid #dee2e6" />
      </li>
    </ul>
    <ul class="pagination d-flex flex-row align-items-center mb-0 mt-2 ml-2">
      <li class="page-item prev-page" :class="{ disabled: value === 1 }">
        <a class="page-link" aria-label="Previous" @click="prevPage">
          <b-icon-chevron-left />
        </a>
      </li>
      <li class="page-item" :class="{ active: value === 1 }" v-if="totalPages > 0"><a class="page-link" @click="changePage">1</a></li>
      <li
        v-if="showPrevMore"
        class="page-item btn-more btn-quickprev more"
        :class="[quickPrevIconClass]"
        @mouseenter="onMouseenter('left')"
        @mouseleave="quickPrevIconClass = 'btn-more'"
        @click="changePage">
        <a class="page-link"></a>
      </li>
      <li class="page-item" :class="{ active: value === item }" :key="item" v-for="item in pager">
        <a class="page-link" @click="changePage">{{ item }}</a>
      </li>
      <li
        class="page-item btn-more btn-quicknext more"
        v-if="showNextMore"
        @click="changePage"
        :class="[quickNextIconClass]"
        @mouseenter="onMouseenter('right')"
        @mouseleave="quickNextIconClass = 'btn-more'">
        <a class="page-link"></a>
      </li>
      <li :class="{ active: value === totalPages }" class="page-item" v-if="totalPages > 1">
        <a class="page-link" aria-label="Next" @click="changePage"> {{ totalPages }}</a>
      </li>
      <li class="page-item next-page" :class="{ disabled: value === totalPages }">
        <a class="page-link" aria-label="Next" @click="nextPage">
          <b-icon-chevron-right />
        </a>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'paginate',
  props: {
    queries: Object,
    fetcher: { type: Function, default: () => {} },
    total: { type: Number, default: 0 },
    showBack: Boolean,
    align: { type: String, default: 'end', description: 'Pagination alignment (e.g center|start|end)' },
  },
  data() {
    return {
      showPrevMore: false,
      showNextMore: false,
      quickPrevIconClass: 'btn-more',
      quickNextIconClass: 'btn-more',
      defaultPagesToDisplay: 7,
      perPageOpts: [
        { value: 10, text: this.$t('general.per_page', { num: 10 }) },
        { value: 20, text: this.$t('general.per_page', { num: 20 }) },
        { value: 50, text: this.$t('general.per_page', { num: 50 }) },
        { value: 100, text: this.$t('general.per_page', { num: 100 }) },
      ],
    }
  },
  computed: {
    value: {
      get() {
        return this.queries.page ?? 1
      },
      set(newVal) {
        this.queries['page'] = newVal
        this.fetcher()
      },
    },
    perPage: {
      get() {
        return this.queries.perPage ?? 10
      },
      set(newVal) {
        if (newVal < this.queries.perPage) {
          this.queries['page'] = 1
          this.queries['perPage'] = newVal
        } else if (newVal > this.queries.perPage) {
          const tmpPage = Math.ceil((this.value * this.queries.perPage) / parseInt(newVal))
          const maxPage = Math.ceil(this.total / parseInt(newVal))
          this.queries['page'] = Math.min(tmpPage, maxPage) // 'page' new value cannot exceed the 'totalPages' new value
          this.queries['perPage'] = newVal
        }
        this.fetcher()
      },
    },
    totalPages() {
      if (this.total > 0) {
        return Math.ceil(this.total / parseInt(this.queries.perPage))
      }
      return 1
    },
    pagesToDisplay() {
      if (this.totalPages > 0 && this.totalPages < this.defaultPagesToDisplay) {
        return this.totalPages
      }
      return this.defaultPagesToDisplay
    },
    pager() {
      const pagerCount = this.pagesToDisplay
      const halfPagerCount = (pagerCount - 1) / 2
      const currentPage = Number(this.value)
      const pageCount = Number(this.totalPages)
      let showPrevMore = false
      let showNextMore = false
      if (pageCount > pagerCount) {
        if (currentPage > pagerCount - halfPagerCount) {
          showPrevMore = true
        }
        if (currentPage < pageCount - halfPagerCount) {
          showNextMore = true
        }
      }
      const array = []
      if (showPrevMore && !showNextMore) {
        const startPage = pageCount - (pagerCount - 2)
        for (let i = startPage; i < pageCount; i++) {
          array.push(i)
        }
      } else if (!showPrevMore && showNextMore) {
        for (let i = 2; i < pagerCount; i++) {
          array.push(i)
        }
      } else if (showPrevMore && showNextMore) {
        const offset = Math.floor(pagerCount / 2) - 1
        for (let i = currentPage - offset; i <= currentPage + offset; i++) {
          array.push(i)
        }
      } else {
        for (let i = 2; i < pageCount; i++) {
          array.push(i)
        }
      }
      const status = []
      status.push(showPrevMore)
      status.push(showNextMore)
      this.changeStatus(status)
      return array
    },
  },
  watch: {
    showPrevMore(val) {
      if (!val) this.quickPrevIconClass = 'btn-more'
    },
    showNextMore(val) {
      if (!val) this.quickNextIconClass = 'btn-more'
    },
  },
  methods: {
    changeStatus(arr) {
      this.showPrevMore = arr[0]
      this.showNextMore = arr[1]
    },
    onMouseenter(direction) {
      if (this.disabled) return
      if (direction === 'left') {
        this.quickPrevIconClass = 'el-icon-d-arrow-left'
      } else {
        this.quickNextIconClass = 'el-icon-d-arrow-right'
      }
    },
    changePage(event) {
      const target = event.target.parentNode
      let newPage = Number(event.target.textContent)
      const currentPage = this.value
      const pagerCountOffset = this.pagesToDisplay - 2
      if (target.className.indexOf('more') !== -1) {
        if (target.className.indexOf('btn-quickprev') !== -1) {
          newPage = currentPage - pagerCountOffset
        } else if (target.className.indexOf('btn-quicknext') !== -1) {
          newPage = currentPage + pagerCountOffset
        }
      }
      if (!isNaN(newPage)) {
        if (newPage < 1) {
          newPage = 1
        }
        if (newPage > this.totalPages) {
          newPage = this.totalPages
        }
      }
      if (newPage !== currentPage) {
        this.queries['page'] = newPage
        this.fetcher()
      }
    },
    nextPage() {
      if (this.value < this.totalPages) {
        this.queries['page'] = this.value + 1
        this.fetcher()
      }
    },
    prevPage() {
      if (this.value > 1) {
        this.queries['page'] = this.value - 1
        this.fetcher()
      }
    },
    firstPage() {
      if (this.value > 1) {
        this.queries['page'] = 1
        this.fetcher()
      }
    },
    lastPage() {
      if (this.value < this.totalPages) {
        this.queries['page'] = this.totalPages
        this.fetcher()
      }
    },
  },
}
</script>
<style scoped>
.btn-more {
  content: '';
  display: block;
  height: 32px; /*height of icon */
  min-width: 40px; /*width of icon */
  align-content: center;
  top: 0px;
  left: -40px;
  background: #fff
    url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgZmlsbD0ibm9uZSIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiB3aWR0aD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTUgMTJINS4wMU0xMiAxMkgxMi4wMU0xOSAxMkgxOS4wMU02IDEyQzYgMTIuNTUyMyA1LjU1MjI4IDEzIDUgMTNDNC40NDc3MiAxMyA0IDEyLjU1MjMgNCAxMkM0IDExLjQ0NzcgNC40NDc3MiAxMSA1IDExQzUuNTUyMjggMTEgNiAxMS40NDc3IDYgMTJaTTEzIDEyQzEzIDEyLjU1MjMgMTIuNTUyMyAxMyAxMiAxM0MxMS40NDc3IDEzIDExIDEyLjU1MjMgMTEgMTJDMTEgMTEuNDQ3NyAxMS40NDc3IDExIDEyIDExQzEyLjU1MjMgMTEgMTMgMTEuNDQ3NyAxMyAxMlpNMjAgMTJDMjAgMTIuNTUyMyAxOS41NTIzIDEzIDE5IDEzQzE4LjQ0NzcgMTMgMTggMTIuNTUyMyAxOCAxMkMxOCAxMS40NDc3IDE4LjQ0NzcgMTEgMTkgMTFDMTkuNTUyMyAxMSAyMCAxMS40NDc3IDIwIDEyWiIgc3Ryb2tlPSIjNEE1NTY4IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iMiIvPjwvc3ZnPg==')
    no-repeat right 0.8rem center/14px 22px !important;
}
.el-icon-d-arrow-left {
  content: '';
  display: block;
  height: 32px; /*height of icon */
  min-width: 40px; /*width of icon */
  align-content: center;
  top: 0px;
  left: -40px;
  background: #fff
    url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGlkPSJMYXllcl8xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA2NCA2NDsiIHZlcnNpb249IjEuMSIgdmlld0JveD0iMCAwIDY0IDY0IiB4bWw6c3BhY2U9InByZXNlcnZlIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48c3R5bGUgdHlwZT0idGV4dC9jc3MiPgoJLnN0MHtmaWxsOiMxMzQ1NjM7fQo8L3N0eWxlPjxnPjxnIGlkPSJJY29uLUNoZXZyb24tTGVmdCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMjM3LjAwMDAwMCwgMzM1LjAwMDAwMCkiPjxwb2x5bGluZSBjbGFzcz0ic3QwIiBpZD0iRmlsbC0zNSIgcG9pbnRzPSItMTk5LjEsLTI4OSAtMjEyLjksLTMwMi43IC0xOTkuMSwtMzE2LjQgLTE5Ny4xLC0zMTQuNCAtMjA4LjksLTMwMi43IC0xOTcuMSwtMjkxICAgICAgLTE5OS4xLC0yODkgICAgIi8+PC9nPjwvZz48L3N2Zz4=')
    no-repeat right 0.8rem center/20px 22px !important;
}
.el-icon-d-arrow-right {
  content: '';
  display: block;
  height: 32px; /*height of icon */
  min-width: 40px; /*width of icon */
  align-content: center;
  top: 0px;
  left: -40px;
  background: #fff
    url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGlkPSJMYXllcl8xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA2NCA2NDsiIHZlcnNpb249IjEuMSIgdmlld0JveD0iMCAwIDY0IDY0IiB4bWw6c3BhY2U9InByZXNlcnZlIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48c3R5bGUgdHlwZT0idGV4dC9jc3MiPgoJLnN0MHtmaWxsOiMxMzQ1NjM7fQo8L3N0eWxlPjxnPjxnIGlkPSJJY29uLUNoZXZyb24tTGVmdCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMjM3LjAwMDAwMCwgMzM1LjAwMDAwMCkiPjxwb2x5bGluZSBjbGFzcz0ic3QwIiBpZD0iRmlsbC0zNSIgcG9pbnRzPSItMjEwLjksLTI4OSAtMjEyLjksLTI5MSAtMjAxLjEsLTMwMi43IC0yMTIuOSwtMzE0LjQgLTIxMC45LC0zMTYuNCAtMTk3LjEsLTMwMi43ICAgICAgLTIxMC45LC0yODkgICAgIi8+PC9nPjwvZz48L3N2Zz4=')
    no-repeat right 0.8rem center/20px 22px !important;
}
</style>
