import { authAxios } from '../../plugins/authenticate';
import axios from '../../plugins/axios'; // protected by permission settings
const options = { headers: { Accept: '*/*', }, }

const service = {
  generalFetcher(relativeUrl, _params) { return axios.get(relativeUrl, { params: _params }) },

  // Admin
  getAdmins(p) { return axios.get('/admin', { params: p }) },
  getAllRoles() { return axios.get('/admin/role', options) },
  getAllPermissions() { return axios.get('/admin/permission', options) },
  getActionLogs(p) { return axios.get('/admin/action-logs', { params: p }) },
  createAdmin(p) { return axios.post('/admin/create/admin', p, options) },
  changePass(p) { return authAxios.post('/admin/reset-valid', p, options) },
  resetPass(p) { return axios.post('/admin/reset-pass-other', p, options) },
  forgetPass(p) { return authAxios.post('/admin/forget-pass', p, options) },
  updateRole(p) { return axios.post('/admin/role', p, options) },
  updateRolePermission(p) { return axios.post('/admin/role/permission', p, options) },

  // User
  getUsers(p) { return axios.get('/admin/user', { params: p }) },
  getUserDetails(p) { return axios.get('/admin/user/profile-get', { params: p }) },
  createUser(p) { return axios.post('/admin/create/user', p, options) },
  updateUserDetails(p) { return axios.post('/admin/user/profile', p, options) },
  resetUserPass(p) { return axios.post('/admin/user/password', p, options) },
  addUserBalance(p) { return axios.post('/admin/user/balance', p, options) },
  activateUser(p) { return axios.post('/admin/user/active', p, options) },

  // Competition
  getCompetitionList(p) { return axios.get('/admin/competition/list', { params: p }) },
  getCompetitionDetails(p) { return axios.get('/admin/competition/details', { params: p }) },
  getQuizTypes() { return axios.get('/admin/competition/quiz-type', options) },
  getQuizList(p) { return axios.get('/admin/competition/quiz-list', { params: p }) },
  getShortName(p) { return axios.get('/admin/competition/short-name', { params: p }) },
  getShuffleTickets(p) { return axios.get('/admin/competition/shuffle-tickets', { params: p }) },
  getPopupInfo(p) { return axios.get('/admin/competition/popup-info', { params: p }) },
  upsertQuiz(p) { return axios.post('/admin/competition/quiz-edit', p, options) },
  createCompetition(p) { return axios.post('/admin/competition/create', p, options) },
  updateCompetition(p) { return axios.post('/admin/competition/update', p, options) },
  publishCompetition(p) { return axios.post('/admin/competition/publish', p, options) },
  closeCompetition(p) { return axios.post('/admin/competition/close', p, options) },
  setTags(p) { return axios.post('/admin/competition/tag-edit', p, options) },
  setPopUp(p) { return axios.post('/admin/competition/set-popup', p, options) },
  allocateRewards(p) { return axios.post('/admin/competition/allocate-reward', p, options) },
  selectWinner(p) { return axios.post('/admin/competition/winner', p, options) },
  freeEntries(p) { return axios.post('/admin/competition/free-entries', p, options) },

  // Catalog
  getCatalogList() { return axios.get('/admin/catalog/catalogs', options) },
  getCatalogCompetitions(p) { return axios.get('/admin/catalog/competitions', { params: p }) },
  upsertCatalog(p) { return axios.post('/admin/catalog/catalog-edit', p, options) },
  updateCatalogCompetitions(p) { return axios.post('/admin/catalog/competitions-update', p, options) },

  // Product
  getProductList(p) { return axios.get('/admin/product/list', { params: p }) },
  getProductDetails(p) { return axios.get('/admin/product/details', { params: p }) },
  getProductInventory(p) { return axios.get('/admin/product/inventory', { params: p }) },
  getInvoices(p) { return axios.get('/admin/product/invoices', { params: p }) },
  createProduct(p) { return axios.post('/admin/product/create', p, options) },
  updateProductInline(p) { return axios.post('/admin/product/edit', p, options) },

  // Attribute
  getAttributeList() { return axios.get('/admin/attribute', options) },
  getProductEntityList() { return axios.get('/admin/attribute/entity-type', options) },

  // Order
  getOrderList(p) { return axios.get('/admin/order/list', { params: p }) },
  getOrderDetails(p) { return axios.get('/admin/order/details', { params: p }) },
  getEmailList(p) { return axios.get('/admin/order/email', { params: p }) },
  updatePayment(p) { return axios.post('/admin/order/payment/update', p, options) },
  generateTicket(p) { return axios.post('/admin/order/ticket', p, options) },
  resendEmail(p) { return axios.post('/admin/order/email/resend', p, options) },

  // Coupon 
  getCouponList(p) { return axios.get('/admin/coupon/list', { params: p }) },
  getCouponCode(p) { return axios.get('/admin/coupon/code-list', { params: p }) },
  upsertCoupon(p) { return axios.post('/admin/coupon/upsert', p, options) },
  deleteCoupon(p) { return axios.post('/admin/coupon/delete', p, options) },
  createCouponCode(p) { return axios.post('/admin/coupon/code', p, options) },
  sendUserCouponCode(p) { return axios.post('/admin/coupon/user', p, options) },
  assignCouponCodeUser(p) { return axios.post('/admin/coupon/code/user', p, options) },

  // Entry List
  getEntryList(p) { return axios.get('/admin/entry-list/list', { params: p }) },
  updateEntryStatus(p) { return axios.post('/admin/entry-list/publish', p, options) },
  updateEntryList(p) { return axios.post('/admin/entry-list/update', p, options) },
  updateEntryLink(p) { return axios.post('/admin/entry-list/update-link', p, options) },
  exportEntryList(p) { return axios.post('/admin/entry-list/export', p, { responseType: 'arraybuffer', }) },

  // Draw List
  getVideoDrawList(p) { return axios.get('/admin/draw-list/list', { params: p }) },
  updateVideoDrawStatus(p) { return axios.post('/admin/draw-list/publish', p) },
  updateVideoDrawList(p) { return axios.post('/admin/draw-list/upsert', p) },

  // User Reward (Winner)
  getWinnerList(p) { return axios.get('/admin/user-reward/list', { params: p }) },
  getWinnerDetails(p) { return axios.get('/admin/user-reward/detail', { params: p }) },
  updateWinnerDetail(p) { return axios.post('/admin/user-reward/update', p, options) },

  // Notification
  getNotificationList(p) { return axios.get('/admin/notification/list', { params: p }) },
  upsertNotification(p) { return axios.post('/admin/notification/upsert', p, options) },
  sendNotification(p) { return axios.post('/admin/notification/send', p, options) },

  // Donation
  getDonationList() { return axios.get('/admin/donation/list', options) },
  upsertDonation(p) { return axios.post('/admin/donation/upsert', p, options) },

  // Report
  getSalesReport(p) { return axios.get('/admin/report/sales', { params: p }) },
  getCompetitionReport(p) { return axios.get('/admin/report/competitions', { params: p }) },
  getCompetitionDailyReport(p) { return axios.get('/admin/report/competition-daily', { params: p }) },
  getUsersReport(p) { return axios.get('/admin/report/users', { params: p }) },
  getUsersWithPurchaseReport(p) { return axios.get('/admin/report/orderUsers', { params: p }) },
  getRegisteredUsers(p) { return axios.get('/admin/report/registeredUsers', { params: p }) },
  getDailyOrders(p) { return axios.get('/admin/report/dailyOrders', { params: p }) },
  getOrderPayments(p) { return axios.get('/admin/report/orderPayments', { params: p }) },
  getNewUsrCompReport(p) { return axios.get('/admin/report/newUsersCompetitions', { params: p }) },
  getStockReport(p) { return axios.get('/admin/report/inventory-history', { params: p }) },
  getReEnterWeeklyReport(p) { return axios.get("/admin/report/re-enter-weekly", { params: p }) },

  // Topup
  getTopupList(p) { return axios.get('/admin/top-up/list', { params: p }) },
  getTopupPayments(p) { return axios.get('/admin/top-up/payments', { params: p }) },
  updateTopup(p) { return axios.post('/admin/top-up/edit', p, options) },
  retrieveTopupPayment(p) { return axios.post('/admin/top-up/retrieve', p, options) },

  // Cashback
  getCashbackOptions(p) { return axios.get('/admin/cashback/list', { params: p }) },
  getCashbackTrans(p) { return axios.get('/admin/cashback/transactions/list', { params: p }) },
  updateCashbackOption(p) { return axios.post('/admin/cashback/edit', p, options) },
  auditCashback(p) { return axios.post('/admin/cashback/transactions/audit', p, options) },
  approveCashback(p) { return axios.post('/admin/cashback/transactions/approve', p, options) },

  // System Config
  getTabConfigs(p) { return axios.get('/admin/system-config/tab-configs', { params: p }) },
  editSysConfigs(p) { return axios.post('/admin/system-config/config-edit', p, options) },

  // Banner
  getBannerList(p) { return axios.get('/admin/banner/list', { params: p }) },
  getCompetitionBanners(p) { return axios.get('/admin/banner/competition-banners', { params: p }) },
  upsertBanner(p) { return axios.post('/admin/banner/edit', p, options) },

  // Dashboard
  getDailySales(p) { return axios.get('/admin/report/dailySales', { params: p }) },
  getUserChannels(p) { return axios.get('/admin/report/userChannels', { params: p }) },
  getFailedPayments(p) { return axios.get('/admin/report/failedPayments', { params: p }) },
  getDailyCompetitionSales(p) { return axios.get('/admin/report/competitionSales', { params: p }) }
}

export default service