<template>
  <base-nav expand>
    <ul class="navbar-nav align-items-center ml-auto ml-md-0">
      <base-dropdown menu-on-right class="nav-item" tag="li" title-tag="a" menu-classes="dropdown-menu dropdown-menu-right py-0 overflow-hidden">
        <a href="#" slot="title" class="nav-link pr-0" @click.prevent>
          <div class="media align-items-center">
            <span class="avatar avatar-sm rounded avatar-image" style="background-image: url('/img/brand/icon.png'); width: 28px; height: 26px; background-color: white"></span>
            <div class="media-body d-none d-lg-flex align-items-center">
              <span class="mb-0 text-sm font-weight-bold">{{ email }}</span>
            </div>
          </div>
        </a>
        <template class="dropdownright">
          <span @click="openPass" class="dropdown-item"><b-icon-shield-lock class="mr-2" />{{ $t('auth.change_password') }}</span>
          <span @click.prevent="logout" class="dropdown-item"><b-icon-power class="mr-2" />{{ $t('auth.logout') }}</span>
        </template>
      </base-dropdown>
    </ul>
    <AdminPassword v-if="$route.path.indexOf('admin-management') == -1" :adminDetails.sync="actionTarget" />
  </base-nav>
</template>
<script>
import AdminPassword from '@/components/Admin/AdminPassword.vue'
export default {
  components: { AdminPassword },
  data() {
    return {
      showMenu: false,
      email: this.$cookies.get('Email'),
      actionTarget: null,
    }
  },
  methods: {
    async logout() {
      try {
        this.$store.dispatch('logout')
      } catch (error) {
        console.log(this.$t('notify.unknown_err'))
      }
    },
    openPass() {
      this.actionTarget = { id: this.$cookies.get('UserID'), email: this.email }
      this.$bvModal.show('admin-change-pass')
    },
  },
}
</script>
