import Vue from 'vue'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
localStorage.setItem('lang', 'en')
export const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: {
    en: require('./en.json'),
    es: require('./es.json'),
  },
})
export default i18n
