<template>
  <b-modal
    lazy
    centered
    :id="id ? id : 'admin-change-pass'"
    :title="isSelf ? $t('auth.change_password') : $t('auth.reset_password')"
    title-class="w-100 d-flex justify-content-center align-self-center"
    header-class="py-2"
    footer-class="py-2"
    body-class="py-0"
    @shown="onShown"
    @hidden="onHidden"
    :busy="isLoading"
  >
    <form id="admin-change-pass-form" @submit.prevent="onSubmit">
      <b-form-group label-for="admin-email" class="m-0">
        <template #label>{{ $t('general.email') }}<span class="text-danger ml-1">*</span></template>
        <b-form-input id="admin-email" :value="adminDetails ? adminDetails.email : null" disabled />
      </b-form-group>
      <b-form-group v-if="isSelf" label-for="admin-pass-current" class="mt-3 mb-0">
        <template #label>{{ $t('auth.current_password') }}<span class="text-danger ml-1">*</span></template>
        <b-form-input id="admin-pass-current" v-model="form['current_pass']" :placeholder="$t('auth.current_password')" required />
      </b-form-group>
      <b-form-group label-for="admin-pass-new" class="mt-3 mb-0">
        <template #label>{{ $t('auth.new_password') }}<span class="text-danger ml-1">*</span></template>
        <b-form-input id="admin-pass-new" v-model="form['pass']" :placeholder="$t('auth.new_password')" required @change="validatePass" />
      </b-form-group>
      <b-form-group label-for="admin-pass-confirm" class="mt-3 mb-0">
        <template #label>{{ $t('auth.confirm_new_password') }}<span class="text-danger ml-1">*</span></template>
        <b-form-input id="admin-pass-confirm" v-model="form['confirm_pass']" :placeholder="$t('auth.confirm_new_password')" required @change="validatePass" />
      </b-form-group>
    </form>
    <template #modal-footer="{ hide }">
      <b-button variant="outline-secondary" @click="hide">{{ $t('action.close') }}</b-button>
      <b-button variant="outline-primary" type="submit" form="admin-change-pass-form">{{ $t('action.submit') }}</b-button>
    </template>
  </b-modal>
</template>
<script>
export default {
  name: 'AdminPassword',
  props: { id: String, adminDetails: Object },
  data() {
    const form = { id: null, current_pass: null, pass: null, confirm_pass: null }
    return {
      isLoading: false,
      form: { ...form },
      initialForm: { ...form },
    }
  },
  computed: {
    isSelf() {
      return this.adminDetails?.email == this.$cookies.get('Email')
    },
  },
  methods: {
    onShown() {
      this.form = { id: this.adminDetails?.id, current_pass: null, pass: null, confirm_pass: null }
    },
    validatePass() {
      const pass = document.getElementById('admin-pass-new'),
        confirm_pass = document.getElementById('admin-pass-confirm')
      confirm_pass.setCustomValidity(pass.value != confirm_pass.value ? this.$t('auth.passwords_not_match') : '')
    },
    onSubmit() {
      this.isLoading = true
      const params = { successMsg: this.$t('auth.change_password_notice[0]'), errorMsg: this.$t('auth.change_password_notice[1]') }
      if (this.isSelf) {
        params['current_password'] = this.form.current_pass
        params['password'] = this.form.confirm_pass
      } else {
        params['admin_id'] = this.form.id
        params['new_password'] = this.form.confirm_pass
      }
      this.$store
        .dispatch(this.isSelf ? 'request/changePass' : 'request/resetPass', params)
        .then((res) => {
          this.isLoading = false
          if (res.status == 200) {
            this.$bvModal.hide('admin-change-pass')
          }
        })
        .catch(() => (this.isLoading = false))
    },
    onHidden() {
      this.form = { ...this.initialForm }
      this.$emit('update:adminDetails', null)
    },
  },
}
</script>
<style scoped>
.form-group label {
  font-size: 0.8rem;
}
</style>
