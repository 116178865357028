<template>
  <b-modal
    lazy
    centered
    id="file-upload"
    :title="$t('entry.upload_entry_list')"
    title-class="w-100 d-flex justify-content-center align-self-center"
    header-class="py-2"
    body-class="py-0"
    footer-class="py-2"
    dialog-class="quiz"
    @cancel="onCancel"
    @hide="onCancel"
    :busy="isLoading">
    <b-form enctype="multipart/form-data" novalidate @submit.prevent="sOnSubmit" id="file-upload-form">
      <b-form-group class="inputDnD">
        <input
          type="file"
          class="form-control-file"
          id="inputFile"
          style="visibility: hidden; cursor: pointer"
          @change="readFile"
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          :data-title="$t('input.drag_drop_file')"
          required />
      </b-form-group>
    </b-form>
    <template #modal-footer="{ cancel }">
      <b-button type="cancel" variant="outline-secondary" @click.prevent="cancel">{{ $t('action.cancel') }}</b-button>
      <b-button type="submit" variant="outline-primary" :disabled="!form.file || isLoading" form="file-upload-form">
        {{ isLoading ? $t('status.loading') : $t('action.submit') }}
      </b-button>
    </template>
  </b-modal>
</template>
<script>
import { base64Encode } from '@/utils/fileUtils'
export default {
  name: 'file-upload',
  props: {
    form: Object,
    onSubmit: Function,
    isLoading: Boolean,
  },
  methods: {
    readFile() {
      var input = document.getElementById('inputFile')
      if (input.files && input.files[0]) {
        this.$set(this.form, 'file', input.files[0])
        base64Encode(input.files[0]).then((res) => {
          input.setAttribute('data-title', input.files[0].name)
        })
      }
    },
    onReset() {
      var input = document.getElementById('inputFile')
      input.value = null //reset excel input
      input.setAttribute('data-title', this.$t('input.drag_drop_file'))
      this.$set(this.form, 'file', null)
    },
    onCancel() {
      this.onReset()
      this.$bvModal.hide('file-upload')
    },
    sOnSubmit() {
      this.onSubmit(this.onReset)
    },
  },
}
</script>
<style scoped>
.inputDnD .form-control-file {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 8em;
  outline: none;
  visibility: hidden;
  cursor: pointer;
  box-shadow: 0 0 5px solid currentColor;
}
.inputDnD .form-control-file:before {
  content: attr(data-title);
  position: absolute;
  left: 0;
  width: 100%;
  min-height: 8em;
  line-height: 2em;
  padding-top: 1.5em;
  opacity: 1;
  visibility: visible;
  text-align: center;
  border: 0.15em dashed grey;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  overflow: visible;
}
.inputDnD .form-control-file:hover:before {
  border-style: solid;
  box-shadow: inset 0px 0px 0px 0.05em grey;
}
.inputDnD {
  margin-bottom: 0px !important;
}
</style>
