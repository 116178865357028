<template>
  <i :class="`fa fa-language text-xl text-warn ${classes}`" v-b-tooltip.hover.topright="$t('notify.translate_notice[2]')" @click.prevent="sOnClick" :style="clickable ? 'cursor: pointer' : ''"></i>
</template>
<script>
export default {
  name: 'icon-translate',
  props: { onClick: Function, classes: { type: String, default: 'ml-1' } },
  data() {
    return {
      clickable: false,
    }
  },
  methods: {
    sOnClick() {
      if (this.onClick) {
        this.onClick()
      }
    },
  },
  created() {
    if (this.onClick) {
      this.clickable = true
    }
  },
}
</script>
