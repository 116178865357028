import i18n from '../lang'
import moment from '../plugins/moment-timezone'
import { TIMEZONE_LIST } from './consts'

export const defaultFormats = [
  'YYYY-MM-DD HH:mm:ss', // for display
  'YYYY-MM-DDTHH:mm:ss', // for inputs
  'YYYY-MM-DD-HH-mm-ss', // for export file
  'YYYY-MM-DD', // day
]

export function getLocalTimezone() {
  var zone = localStorage.getItem('timezone')
  if (!zone || TIMEZONE_LIST.findIndex((x) => x === zone) === -1) {
    zone = TIMEZONE_LIST[0]
  }
  return zone
}

export function setLocalTimezone(val) {
  if (!val || TIMEZONE_LIST.findIndex((x) => x === val) === -1) {
    val = TIMEZONE_LIST[0]
  }
  localStorage.setItem('timezone', val)
  moment.tz.setDefault(val)
  return val
}

/**
 * General handler
 * @param {string | object} v value
 * @param {boolean | number | string} f format
 * @param {boolean} now
 * @param {boolean} nullable
 * @param {function} constructMomentObj callback to construct a moment obj
 * @returns {object | string} returns a moment obj or a string
 */
function formatter(v, f, now, nullable, constructMomentObj) {
  if (!now && !v) {
    return nullable ? null : i18n.t('no_data')
  }
  if (now) {
    v = undefined
  }
  if (f === false) {
    return constructMomentObj(v)
  } else {
    return constructMomentObj(v).format(defaultFormats[f] ?? f ?? defaultFormats[0])
  }
}

/**
 * Format a utc time into local time
 * @param {string | object} v input value must be in utc mode
 * @param {boolean | number | string} f format
 * @param {boolean} now
 * @param {boolean} nullable
 * @returns {object | string} return value in local time mode
 */
export function formatLocalDateTime(v, f, now, nullable) {
  return formatter(v, f, now, nullable, (val) => {
    return moment.utc(val).tz(getLocalTimezone())
  })
}

/**
 * Format a local time into utc mode
 * @param {string | object} v input value in local tz mode
 * @param {boolean | number | string} f format
 * @param {boolean} now
 * @param {boolean} nullable
 * @returns {object | string} return value in utc mode
 */
export function formatUtcDateTime(v, f, now, nullable) {
  return formatter(v, f, now, nullable, (val) => {
    return moment.tz(val, getLocalTimezone()).utc()
  })
}

/**
 *
 * @param {string | object} v value in local tz mode
 * @param {boolean | number | string} f format
 * @param {boolean} now
 * @param {boolean} nullable
 * @returns
 */
export function formatDayStartUtc(v, f, now, nullable) {
  return formatter(v, f, now, nullable, (val) => {
    return moment.tz(moment(v).format('YYYY-MM-DD'), getLocalTimezone()).startOf('day').utc()
  })
}

/**
 *
 * @param {string | object} v value in local tz mode
 * @param {boolean | number | string} f format
 * @param {boolean} now
 * @param {boolean} nullable
 * @returns
 */
export function formatDayEndUtc(v, f, now, nullable) {
  return formatter(v, f, now, nullable, (val) => {
    return moment.tz(moment(v).format('YYYY-MM-DD'), getLocalTimezone()).endOf('day').utc()
  })
}

export function setupDateRanges(week) {
  let today = new Date()
  today.setHours(12, 0, 0, 0)

  let yesterday = new Date()
  yesterday.setDate(yesterday.getDate() - 1)
  yesterday.setHours(12, 0, 0, 0)

  let ranges = {
    Today: [today, today],
    Yesterday: [yesterday, yesterday],
  }

  if (week) {
    ranges['Last 7 days'] = [new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000), yesterday]
  }

  ranges['This month'] = [new Date(today.getFullYear(), today.getMonth(), 1, 12), new Date(today.getFullYear(), today.getMonth() + 1, 0, 12)]
  ranges['Last month'] = [new Date(today.getFullYear(), today.getMonth() - 1, 1, 12), new Date(today.getFullYear(), today.getMonth(), 0, 12)]
  ranges['This year'] = [new Date(today.getFullYear(), 0, 1, 12), new Date(today.getFullYear(), 11, 31, 12)]

  return ranges
}
