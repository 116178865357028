<template>
  <span v-b-tooltip.hover.right="{ title: content, disabled: !showTooltip }" @click="onClick" :class="classes">{{ content }}</span>
</template>
<script>
export default {
  name: 'tooltip-span',
  props: {
    content: String,
    onClick: { type: Function, default: () => {} },
    classes: { type: String, default: '' },
  },
  data() {
    return {
      showTooltip: false,
    }
  },
  mounted() {
    if (this.$el) {
      if (this.$el.parentElement.offsetWidth - 48 <= this.$el.offsetWidth) {
        this.showTooltip = true
        // parent element text truncate
        this.$el.parentElement.style.overflow = 'hidden'
        this.$el.parentElement.style.textOverflow = 'ellipsis'
        this.$el.parentElement.style.whiteSpace = 'nowrap'
      }
    }
  },
}
</script>
