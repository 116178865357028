export default {
  data() {
    const langOpts = this.$i18n.availableLocales.map((locale) => ({
      value: locale,
      text: this.$t(locale == 'es' ? 'spanish' : 'english'),
      img: locale == 'es' ? '/img/icons/flag-mexico-96.png' : '/img/icons/flag-great-britain-96.png',
    }))

    return {
      langOpts: langOpts,
    }
  },
  methods: {
    setLang(val) {
      if (this.$i18n.availableLocales.indexOf(val) > -1) {
        this.$i18n.locale = val
        this.$moment.locale(val)
        localStorage.setItem('lang', val)
      }
    },
  },
}
