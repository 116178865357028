<template>
  <component
    :is="baseComponent"
    :to="link.path ? link.path : '/'"
    class="nav-item"
    :class="{ active: isActive && !sidebarOpen }"
    tag="li"
    style="position: relative"
    @mouseenter="menuMouseEnter"
    @mouseleave="menuMouseLeave"
    v-click-outside="menuClickOutside"
  >
    <a v-if="isMenu" class="sidebar-menu-item nav-link" :class="{ active: isActive }" :aria-expanded="!collapsed" data-toggle="collapse" @click.prevent="toggleCollapsed" :title="link.name">
      <template v-if="addLink">
        <span class="nav-link-text">{{ link.name }}<b class="caret"></b></span>
      </template>
      <template v-else>
        <b-icon :icon="link.icon" class="mr-2" />
        <div :style="sidebarItemStyle">
          <span class="nav-link-text">{{ link.name }}<b class="caret"></b></span>
        </div>
      </template>
    </a>

    <collapse-transition>
      <div
        v-if="$slots.default || isMenu"
        v-show="!collapsed"
        class="collapse show"
        :class="{ sidebarMenuItemStyle: sidebarItemCollapseStyle, displayNone: sidebarItemCollapseStyleMobile }"
        @mouseover="childMenuShow"
        @mouseleave="childMenuCollapse"
      >
        <ul class="nav nav-sm flex-column">
          <slot></slot>
        </ul>
      </div>
    </collapse-transition>

    <slot name="title" v-if="children.length === 0 && !$slots.default && link.path">
      <component
        :to="link.path"
        @click.native="linkClick"
        :is="elementType(link, false)"
        class="nav-link"
        :class="{ active: link.active || currentRoute }"
        :target="link.target"
        :href="link.path"
        :style="sidebarChildMenu"
        :title="link.name"
      >
        <template v-if="addLink">
          <span class="nav-link-text">{{ link.name }}</span>
        </template>
        <template v-else>
          <b-icon :icon="link.icon" class="mr-2" />
          <span class="nav-link-text" :style="sidebarItemStyle">{{ link.name }}</span>
        </template>
      </component>
    </slot>
  </component>
</template>
<script>
import { CollapseTransition } from 'vue2-transitions'
import { mapGetters } from 'vuex'
export default {
  name: 'sidebar-item',
  components: {
    CollapseTransition,
  },
  props: {
    menu: Boolean,
    link: {
      type: Object,
      default: () => {
        return {
          name: '',
          path: '',
          children: [],
        }
      },
    },
  },
  provide() {
    return {
      addLink: this.addChild,
      removeLink: this.removeChild,
    }
  },
  inject: {
    addLink: { default: null },
    removeLink: { default: null },
    autoClose: {
      default: true,
    },
  },
  data() {
    return {
      children: [],
      collapsed: true,
      timer: null,
      childMenu: false,
      otherMenu: false,
      // pathStartsWithUsers: []
    }
  },
  computed: {
    baseComponent() {
      return this.isMenu || this.link.isRoute ? 'li' : 'router-link'
    },
    isMenu() {
      return this.children.length > 0 || this.menu === true
    },
    isActive() {
      if (this.$route && this.$route.path) {
        let matchingRoute = this.children.find((c) => this.$route.path.startsWith(c.link.path))
        if (matchingRoute !== undefined) {
          return true
        }
      }
      return false
    },
    ...mapGetters({ sidebarOpen: 'settings/sidebarOpen' }),
    sidebarItemStyle() {
      return this.sidebarOpen ? '' : 'display:none'
    },
    sidebarItemCollapseStyle() {
      if (this.sidebarOpen) {
        return false
      } else if (!this.collapsed) {
        return true
      } else {
        return false
      }
    },
    sidebarItemCollapseStyleMobile() {
      if (this.sidebarOpen) {
        return false
      } else if (!this.collapsed) {
        return false
      } else {
        return true
      }
    },
    sidebarChildMenu() {
      if (this.sidebarOpen) {
        return null
      } else {
        return 'padding-left: 24px'
      }
    },
    currentRoute() {
      // if (this.$route.path === '/reports/users-competitions' && this.link.path === '/users') {
      //   return false
      // } else {
      return this.$route.path.indexOf(this.link.path) === 0
      // }
    },
  },
  watch: {
    sidebarOpen(newVal) {
      if (newVal == false) {
        this.childMenuCollapse()
      } else if (newVal == true) {
        this.childMenuShow()
      }
    },
  },
  methods: {
    addChild(item) {
      const index = this.$slots.default.indexOf(item.$vnode)
      this.children.splice(index, 0, item)
    },
    removeChild(item) {
      const tabs = this.children
      const index = tabs.indexOf(item)
      tabs.splice(index, 1)
    },
    elementType(link, isParent = true) {
      if (link.isRoute === false) {
        return isParent ? 'li' : 'a'
      } else {
        return 'router-link'
      }
    },
    linkClick() {
      if (this.autoClose && this.$sidebar && this.$sidebar.showSidebar === true) {
        this.$sidebar.displaySidebar(false)
      }
    },
    toggleCollapsed() {
      if (!this.sidebarOpen) {
        return
      }
      this.collapsed = !this.collapsed
    },
    menuMouseEnter() {
      this.otherMenu = true
      if (this.sidebarOpen) {
        return
      }
      if (this.collapsed) {
        this.collapsed = false
      }
    },
    menuMouseLeave() {
      if (this.sidebarOpen) {
        return
      }
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        if (this.childMenu || this.otherMenu) {
          return
        } else if (!this.collapsed) {
          this.collapsed = true
        }
      }, 500)
    },
    menuClickOutside() {
      // if (this.isActive && this.isMenu) {
      //   this.collapsed = false
      // } else
      if (!this.collapsed) {
        this.collapsed = true
      }
    },
    childMenuShow() {
      this.childMenu = true
    },
    childMenuCollapse() {
      this.childMenu = false
      this.otherMenu = false
      this.menuMouseLeave()
    },
  },
  mounted() {
    if (this.addLink) {
      this.addLink(this)
    }
    if (this.isActive && this.isMenu) {
      this.collapsed = false
    }
  },
  destroyed() {
    if (this.$el && this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el)
    }
    if (this.removeLink) {
      this.removeLink(this)
    }
  },
}
</script>
<style scoped>
.sidebar-menu-item {
  cursor: pointer;
}
.sidebar-mini {
  text-transform: uppercase;
  width: 15px;
  margin-right: 15px;
  text-align: center;
  letter-spacing: 1px;
  position: relative;
  float: left;
  display: initial;
}
.navbar-dark .navbar-nav .nav-link:hover {
  background-color: #0e0e21;
  color: #5e72e4 !important;
}
.nav-item .active {
  background-color: #0e0e21;
  color: #5e72e4 !important;
}
.sidebarMenuItemStyle {
  display: block;
  position: fixed;
  left: 60px;
  transform: translateY(-50px);
  z-index: 999;
  background-color: rgb(14, 27, 47);
  border: 1px solid #e8e8e8;
  color: rgba(0, 0, 0, 0.65);
}
.displayNone {
  display: none;
}
</style>
