import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import Vue from 'vue'
import VueAxios from 'vue-axios'
import VueLoading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import Notifications from 'vue-notification'
import App from './App.vue'
import i18n from './lang/index'
import ArgonDashboard from './plugins/argon-dashboard'
import axios from './plugins/axios'
import VueCookies from './plugins/cookies'
import moment from './plugins/moment-timezone'
import './plugins/registerServiceWorker'
import router from './router'
import store from './store'
// Import Bootstrap an BootstrapVue CSS files (order is important)
//import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import './assets/global.css'

Vue.use(VueAxios, axios) // use VueAxios to integrate axios to Vue
Vue.use(VueCookies)
Vue.prototype.$cookies = VueCookies

moment.tz.setDefault('Europe/London')
Vue.prototype.$moment = moment
Vue.config.productionTip = false

Vue.use(Notifications)
Vue.use(ArgonDashboard)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(VueLoading)
Vue.component('loading', VueLoading)

const app = new Vue({
  router: router,
  store: store,
  el: '#app',
  i18n: i18n,
  render: (h) => h(App),
})

store.$app = app
