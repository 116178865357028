<template>
  <b-badge pill :variant="['warning', 'info'][storeId - 1]" class="bg-transparent text-capitalize" style="min-width: fit-content; width: fit-content">
    {{ [$t('uk'), $t('address.mexico')][storeId - 1] }}
  </b-badge>
</template>
<script>
export default {
  name: 'store-badge',
  props: { store_id: [String, Number] },
  computed: {
    storeId() {
      return Number(this.store_id)
    },
  },
}
</script>
