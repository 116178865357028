export default {
  methods: {
    // getMaxFontNum(refName, min, max) {
    //     const tmp = Math.floor(this.$refs[`${refName}`]?.parentElement?.clientWidth / 8 - 15)
    //     if (min && max) {
    //         return tmp < min ? min : tmp > max ? max : tmp
    //     } else if (min) {
    //         return tmp > min ? tmp : min
    //     } else if (max) {
    //         return tmp < max ? tmp : max
    //     } else {
    //         return tmp > 30 ? tmp : 30
    //     }
    // },
    hasSlot(s) {
      return !!this.$slots[s] || !!this.$scopedSlots[s]
    },
    compareTime(end, start, equal, nullable) {
      if (start && end) {
        const a = Number(this.$moment(end).unix())
        const b = Number(this.$moment(start).unix())
        if (equal) {
          return a >= b
        } else {
          return a > b
        }
      } else if (!start || !end) {
        return nullable ? null : true
      } else {
        return false
      }
    },
    formatNumbersInput(source, sKey, target, tKey) {
      if (source[sKey]) {
        const values = source[sKey].split(/[^0-9]+/)
        if (values && values.length >= 1 && values[0] !== '') {
          this.$set(target, tKey, `[${values}]`)
          if (target[sKey] && sKey != tKey) {
            this.$set(target, sKey, undefined)
          }
        }
      }
    },
  },
}
