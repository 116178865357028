import AuthLayout from '@/layout/AuthLayout'
import DashboardLayout from '@/layout/DashboardLayout'
import Vue from 'vue'
import Router from 'vue-router'
import axios from '../plugins/axios'
import vueCookies from '../plugins/cookies'
import { API_LIST } from '../utils/consts'
import guest from './middleware/guest'
import protectedRoutes from './protectedRoutes'

Vue.use(Router)

const router = new Router({
  linkExactActiveClass: 'active',
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: 'login',
      component: AuthLayout,
      children: [
        {
          path: '/login',
          name: 'login',
          component: () => import('../views/Auth/Login.vue'),
          meta: { middleware: guest, keepAlive: false },
        },
      ],
    },
    {
      path: '/',
      redirect: 'dashboard',
      component: DashboardLayout,
      children: protectedRoutes,
    },
    {
      path: '*',
      beforeEnter: (to, from, next) => {
        next('/404')
      },
    },
    {
      path: '/404',
      name: '404',
      component: () => import('@/views/404'),
    },
  ],
})
// Creates a `nextMiddleware()` function which not only
// runs the default `next()` callback but also triggers
// the subsequent Middleware function.
function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index]
  // If no subsequent Middleware exists,
  // the default `next()` callback is returned.
  if (!subsequentMiddleware) return context.next

  return (...parameters) => {
    // Run the default Vue Router `next()` callback first.
    context.next(...parameters)
    // Then run the subsequent Middleware with a new
    // `nextMiddleware()` callback.
    const nextMiddleware = nextFactory(context, middleware, index + 1)
    subsequentMiddleware({ ...context, next: nextMiddleware })
  }
}

function noPermission(path) {
  const attempt = protectedRoutes.find((x) => x.path === path)?.permissionId
  if (attempt) {
    const permissions = JSON.parse(localStorage.getItem('permissions'))
    return permissions?.length > 0 && !permissions.some?.((item) => item.id == attempt)
  } else {
    return false
  }
}

router.beforeEach((to, from, next) => {
  if (noPermission(to.path)) {
    Vue.notify({ group: 'notification-group', type: 'warn', title: 'NO PERMISSION', text: 'This account does not have the permission to access the target page.' })
    return
  }

  if (!vueCookies.get('awsS3ConfId') || !vueCookies.get('awsS3ConfSecret')) {
    axios.get(API_LIST.get.awsS3Conf).then((res) => {
      if (res?.data?.data?.key_id && res?.data?.data.key_secret) {
        vueCookies.set('awsS3ConfId', res.data.data.key_id, process.env.VUE_APP_USER_SESSION_TIMEOUT)
        vueCookies.set('awsS3ConfSecret', res.data.data.key_secret, process.env.VUE_APP_USER_SESSION_TIMEOUT)
      }
    })
  }

  const history = from.params.history || {}
  if (from.params.preservedParams) {
    history[`${from.fullPath}`] = from.params.preservedParams
  }
  to.params['history'] = history
  const saved = history[`${to.fullPath}`]
  if (saved) {
    to.params['prev'] = saved
  }
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware) ? to.meta.middleware : [to.meta.middleware]
    const context = { from, next, to, router }
    const nextMiddleware = nextFactory(context, middleware, 1)
    return middleware[0]({ ...context, next: nextMiddleware })
  } else {
    return next()
  }
})

export default router
