import i18n from '../lang/index.js'

export function formatBytes(bytes, decimals = 2) {
  if (!notNaN(bytes)) {
    return i18n.t('no_data')
  } else if (typeof bytes === 'string') {
    return bytes
  } else if (bytes === 0) {
    return '0 Bytes'
  } else {
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
  }
}

export function formatWithCurrency(val, currency) {
  if (!notNaN(val)) {
    return i18n.t('no_data')
  } else {
    const v = typeof val == 'number' ? val : Number(val)
    return (
      (v < 0 ? '-' : '') +
      (currency === 'GBP' ? '£' : currency === 'MXN' ? 'Mex$' : currency) +
      v.toLocaleString('en-US', { signDisplay: 'never', minimumFractionDigits: 2, maximumFractionDigits: 2 })
    )
  }
}

export function formatWithGBP(val) {
  if (!notNaN(val)) {
    val = 0
  } else if (typeof val == 'string') {
    val = Number(val)
  }
  return val.toLocaleString('en-US', {
    style: 'currency',
    currency: 'GBP',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
}

export function formatWith2Digits(val) {
  if (!notNaN(val)) {
    val = 0
  } else if (typeof val == 'string') {
    val = Number(val)
  }
  return val.toLocaleString('en-US', { useGrouping: false, minimumFractionDigits: 2, maximumFractionDigits: 2 })
}

export function extractWithCurrency(val) {
  if (typeof val == 'undefined' || val == null) {
    return i18n.t('no_data')
  } else {
    const i = val.search(/[£$]{1}/)
    const j = ['£', 'Mex$'].indexOf(val.slice(0, i + 1))
    return { currency: ['GBP', 'MXN'][j], amount: parseFloat(val.slice(i + 1)).toFixed(2) }
  }
}

export function sumTotal(arr, key) {
  return arr.reduce((acc, item) => floatAddition(acc, item[key] || 0), 0)
}

/**
 * More accurate float number calculation
 */

export const floatAddition = (arg1, arg2) => {
  if (arg1 == 0) return arg2
  if (arg2 == 0) return arg1
  const str1 = floatToString(arg1)
  const str2 = floatToString(arg2)
  const digitLen1 = str1.split('.')[1]?.length ?? 0
  const digitLen2 = str2.split('.')[1]?.length ?? 0
  const power = Math.pow(10, Math.max(digitLen1, digitLen2))
  const diff = Math.abs(digitLen1 - digitLen2)
  if (diff > 0) {
    const diffPower = Math.pow(10, diff)
    if (digitLen1 > digitLen2) {
      arg1 = Number(str1.replace('.', ''))
      arg2 = Number(str2.replace('.', '')) * diffPower
    } else {
      arg1 = Number(str1.replace('.', '')) * diffPower
      arg2 = Number(str2.replace('.', ''))
    }
  } else {
    arg1 = Number(str1.replace('.', ''))
    arg2 = Number(str2.replace('.', ''))
  }
  return (arg1 + arg2) / power
}

export const floatSubtraction = (arg1, arg2) => {
  if (arg2 == 0) return arg1
  const digitLen1 = floatToString(arg1).split('.')[1]?.length ?? 0
  const digitLen2 = floatToString(arg2).split('.')[1]?.length ?? 0
  const fixed = digitLen1 >= digitLen2 ? digitLen1 : digitLen2
  const power = Math.pow(10, fixed)
  return ((arg1 * power - arg2 * power) / power).toLocaleString('en-US', {
    useGrouping: false,
    minimumFractionDigits: fixed,
    maximumFractionDigits: fixed > 20 ? 20 : fixed,
  })
}

export const floatMultiplication = (arg1, arg2) => {
  if (arg1 == 0 || arg2 == 0) return 0
  if (arg1 == 1) return arg2
  if (arg2 == 1) return arg1
  let digitLen = 0
  const str1 = floatToString(arg1)
  const str2 = floatToString(arg2)
  digitLen += str1.split('.')[1]?.length ?? 0
  digitLen += str2.split('.')[1]?.length ?? 0
  return (Number(str1.replace('.', '')) * Number(str2.replace('.', ''))) / Math.pow(10, digitLen)
}

export const floatDivision = (arg1, arg2) => {
  if (arg1 == 0 || arg2 == 0) return 0
  if (arg2 == 1) return arg1
  const str2 = floatToString(arg2)
  const divisor = Number(str2.replace('.', ''))
  if (divisor == 0) {
    return 0
  } else {
    const str1 = floatToString(arg1)
    const digitLen1 = str1.split('.')[1]?.length ?? 0
    const digitLen2 = str2.split('.')[1]?.length ?? 0
    const dividend = Number(str1.replace('.', ''))
    const power = Math.pow(10, digitLen2 - digitLen1)
    return (dividend / divisor) * power
  }
}

export const notNaN = (arg) => {
  return typeof arg != 'undefined' && arg != null && !isNaN(arg)
}

export const floatToString = (arg) => {
  return typeof arg == 'string' ? arg : arg.toLocaleString('en-US', { useGrouping: false, maximumFractionDigits: 20 })
}
