<template>
  <b-badge pill :variant="variant">{{ value + ' ' + text }}</b-badge>
</template>
<script>
import { notNaN } from '@/utils/numberUtils'

export default {
  name: 'http-status-badge',
  props: {
    value: [String, Number],
  },
  data() {
    return {
      variant: null,
      text: null,
    }
  },
  methods: {
    update(newVal) {
      const status = notNaN(newVal) ? Number(newVal) : 0
      if (status > 599) {
        this.text = 'Unknown Error'
        this.variant = 'warning'
      } else if (status >= 500) {
        this.text = 'Server Error'
        this.variant = 'danger'
      } else if (status >= 400) {
        this.text = 'Client Error'
        this.variant = 'danger'
      } else if (status >= 300) {
        this.text = 'Redirection'
        this.variant = 'secondary'
      } else if (status >= 200) {
        this.text = 'Successful'
        this.variant = 'success'
      } else if (status >= 100) {
        this.text = 'Informational'
        this.variant = 'default'
      } else {
        this.text = 'Unknown Error'
        this.variant = 'warning'
      }
    },
  },
  watch: {
    value(newVal, oldVal) {
      this.update(newVal)
    },
  },
  created() {
    this.update(this.value)
  },
}
</script>
