import Vue from 'vue'

/**
 * @param {string} str Target string
 * @param {number} limit Result string length limit
 * @param {boolean} isPrepend Specify if to add append/prepend content
 * @returns {string} Result string
 */
export function shortenString(str, limit, isPrepend) {
  if (str) {
    const len = str.length
    return len < limit ? str : isPrepend ? `...${str.substring(len - limit, len - 1)}` : `${str.substring(0, limit - 1)}...`
  }
}

export function getRandomStr(length) {
  var result = ''
  var characters = 'abcdefghijklmnopqrstuvwxyz0123456789'
  var charactersLength = characters.length
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

/**
 * @param {any} v value
 * @param {string} k key
 * @param {object} i item
 * @returns {any} value
 */
export function noTransFormatter(v, k, i) {
  // regex /(?<={"e[ns]":).*(?=})/ contains lookbehind syntax which is not supported in Safari
  try {
    if (/{"e[ns]":.*(?=})}/.test(v)) {
      const obj = JSON.parse(v)
      for (const key in obj) {
        if (k) {
          i[`${k}Lang`] = `${key}`
        }
        v = obj[key]
      }
    }
    return v
  } catch (error) {
    return v
  }
}

export function isEmptyObj(obj) {
  let bool = true
  for (const key in obj) {
    bool = bool && !key
  }
  return bool
}

export function notifySuccess(res, str) {
  Vue.notify({
    group: 'root',
    type: res.status === 200 || res.status === 204 ? 'success' : 'info',
    title: 'Success',
    text: str ?? res.config?.method + ' ' + res.config?.url.substring(res.config?.baseURL?.length ?? 0) + ' ' + res.data?.message ?? res.data?.data?.message,
  })
}

export function notifyError(err, str) {
  if (err?.message?.indexOf('REPEATED REQUEST') > -1) {
    Vue.notify({ group: 'root', type: 'warn', title: 'REPEATED REQUEST', text: err.message.slice(err.message.indexOf(':') + 1) })
  } else if (err?.message?.indexOf('NO PERMISSION') > -1) {
    Vue.notify({ group: 'root', type: 'warn', title: 'NO PERMISSION', text: err.message.slice(err.message.indexOf(':') + 1) })
  } else {
    Vue.notify({
      group: 'root',
      type: 'error',
      title: 'Error ' + (err?.response?.data?.code ?? err?.response?.status ?? err?.code ?? ''),
      text: err?.response?.data?.message ?? err?.message ?? err?.toString() ?? str,
    })
  }
}

export function notify(type, str) {
  Vue.notify({ group: 'root', type: type, title: str })
}

export function isValidHttpUrl(string) {
  let url
  try {
    url = new URL(string)
  } catch (e) {
    return false
  }
  return url.protocol === 'http:' || url.protocol === 'https:'
}

export const notNullStr = (arg) => {
  return typeof arg != 'undefined' && arg != null && arg != ''
}
