<template>
  <span :class="cls">
    {{ available ? formatWithGBP(floatAddition(available, pending ? pending : 0)) : '-' }} /
    <span class="text-success">
      {{ available ? formatWithGBP(available) : '-' }}
    </span>
    /
    <span class="text-orange">
      {{ pending ? formatWithGBP(pending, -1) : '-' }}
    </span>
  </span>
</template>
<script>
import { floatAddition, formatWithGBP } from '@/utils/numberUtils';

export default {
  name: 'balance-span',
  props: {
    available: String || Number,
    pending: String || Number,
    cls: String,
  },
  methods: {
    floatAddition,
    formatWithGBP,
  },
}
</script>
