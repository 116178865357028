import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({ permissionIds: 'permissionIds' }),
  },
  methods: {
    hasPermission(id) {
      return this.permissionIds?.indexOf(id) > -1
    },
    pushCompDetails(comp) {
      this.$router.push({
        name: 'Competition Details',
        // path: '/competitions/competition-list/details', // `params` cannot be used alongside `path`
        query: { id: comp.id },
        params: { competition: comp },
      })
    },
    pushCompEdit(comp) {
      this.$router.push({
        name: 'Competition Edit',
        // path: '/competitions/competition-list/edit',
        query: { id: comp.id },
        params: { ...comp },
      })
    },
    pushProductEdit(product) {
      this.$router.push({
        name: 'Product Edit',
        // path: '/competitions/product-management/edit',
        query: { id: product.id },
        params: { ...product },
      })
    },
  },
}
