import Axios from 'axios'
import vueAuth from './authenticate'

// Prevent repeat request in a short time, e.g. double click
const pendingReq = new Set()
function addPendingReq(key) {
  pendingReq.add(key)
}
function removePendingReq(key) {
  if (pendingReq.has(key)) {
    pendingReq.delete(key)
  }
}
function removeCurrentReq(key) {
  if (pendingReq.has(key)) {
    throw new Error('REPEATED REQUEST:' + key)
  }
}

// Prevent no permission request
function removeUnauthorizedReq(method, path) {
  const permissions = JSON.parse(localStorage.getItem('permissions'))
    ?.filter((x) => x.method.toLowerCase() == method)
    .map((x) => '/' + x.path)
  if (permissions.indexOf(path) == -1) {
    throw new Error(`NO PERMISSION: ${method} ${path}`)
  }
}

const axios = Axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Access-Control-Allow-Headers': 'X-CSRF-Token; Content-Type; Accept-Language',
  },
  // withCredentials: true,
})

axios.interceptors.request.use(
  (config) => {
    // do something before request is sent
    if (config.url !== '/login' && vueAuth.isAuthenticated()) {
      config.headers['Authorization'] = vueAuth.getToken()
    }
    removeUnauthorizedReq(config.method, config.url)
    removeCurrentReq(config.url)
    addPendingReq(config.url)
    config.headers['Accept-Language'] = `${localStorage.getItem('lang') ?? 'en'}`
    return config
  },
  (error) => {
    // do something with request error
    if (error.response && 419 === error.response.status) {
      window.location.reload()
    }
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  (response) => {
    removePendingReq(response.config.url)
    return response
  },
  (error) => {
    const key = error.config?.url ?? error.response?.config?.url
    key ? removePendingReq(key) : pendingReq.clear()
    return Promise.reject(error)
  }
)

export default axios
