import vueAuth from '../../plugins/authenticate'
import vueCookies from '../../plugins/cookies'
import router from '../../router'

const requestOptions = { headers: { Accept: '*/*' } }
const tokenType = 'Bearer '
const sessionTimeout = process.env.VUE_APP_USER_SESSION_TIMEOUT // in seconds

const state = {
  // init value (also the value after refresh, use cookie and storage to persist value)
  isAuthenticated: vueAuth.isAuthenticated(),
  isSuperAdmin: vueCookies.get('RoleName') == 'super-admin',
  store: vueCookies.get('Store') ? Number(vueCookies.get('Store')) : null,
  allowMultiStores: vueCookies.get('RoleName') == 'super-admin' && vueCookies.get('Store') == 2,
  permissions: JSON.parse(localStorage.getItem('permissions')),
  permissionIds: JSON.parse(localStorage.getItem('permissions'))?.map((x) => x.id),
}

const getters = {}
for (const key in state) {
  getters[key] = (state) => state[key]
}

const mutations = {
  mutate(state, payload) {
    state[payload.property] = payload.with
  },
}

const actions = {
  updateCookie(context, payload) {
    const val = payload.val ?? vueCookies.get(payload.key)
    vueCookies.set(payload.key, val, payload.time ?? sessionTimeout)
  },
  login({ commit }, payload) {
    return vueAuth.login(payload, requestOptions).then((response) => {
      const { id, email, role, token, store_id, permissions } = response.data.data

      vueCookies.set('UserID', id, sessionTimeout)
      vueCookies.set('Email', email, sessionTimeout)
      vueCookies.set('RoleName', role.name, sessionTimeout)
      vueCookies.set('RolePriority', role.priority, sessionTimeout)
      vueCookies.set('Token', tokenType + token, sessionTimeout)
      vueCookies.set('Store', store_id, sessionTimeout)
      vueAuth.storage.setItem(vueAuth.tokenName, tokenType + token)

      commit('mutate', { property: 'isAuthenticated', with: vueAuth.isAuthenticated() })
      commit('mutate', { property: 'store', with: store_id })
      const isSuperAdmin = role.name == 'super-admin'
      commit('mutate', { property: 'isSuperAdmin', with: isSuperAdmin })
      commit('mutate', { property: 'allowMultiStores', with: isSuperAdmin && store_id == 2 })
      commit('mutate', { property: 'permissions', with: permissions })
      commit('mutate', { property: 'permissionIds', with: permissions.map((x) => x.id) })

      // localStorage.setItem('store', JSON.stringify(store_id))
      localStorage.setItem('permissions', JSON.stringify(permissions))

      router.push({ path: '/dashboard' })
    })
  },

  logout(context, payload) {
    return vueAuth.logout().then(() => {
      vueCookies.remove('UserID')
      vueCookies.remove('Email')
      vueCookies.remove('RoleName')
      vueCookies.remove('RolePriority')
      vueCookies.remove('Token')
      vueCookies.remove('Store')
      vueCookies.remove('awsS3ConfId')
      vueCookies.remove('awsS3ConfSecret')

      localStorage.removeItem('permissions')
      // localStorage.removeItem('store')

      context.commit('mutate', { property: 'isAuthenticated', with: vueAuth.isAuthenticated() })
      context.commit('mutate', { property: 'isSuperAdmin', with: false })
      context.commit('mutate', { property: 'store', with: null })
      context.commit('mutate', { property: 'allowMultiStores', with: false })
      context.commit('mutate', { property: 'permissions', with: null })
      context.commit('mutate', { property: 'permissionIds', with: null })

      router.push({ path: '/login' })
    })
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
